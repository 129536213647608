import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Grid } from '@mui/material';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="Erste Seite"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Vorherige Seite"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Nächste Seite"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="Letzte Seite"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(data) {
    const tstamp = new Date(data.tstamp).toLocaleString()
    const from = data.fromaddr
    const to = data.toaddr
    const groupCount = data.groupCount
    const mos = new Intl.NumberFormat('de', { minimumFractionDigits: 1, maximumFractionDigits: 2 }).format(data.mos)
    const id = data.id
    const public_id = data.publicID;
    var detail_r = []
    var detail_s = []

    for (let obj in data.r) {
        detail_r.push(obj + " " + data.r[obj])
    }

    for (let obj in data.s) {
        detail_s.push(obj + " " + data.s[obj])
    }

    detail_r.push("cl_local_adress " + data.cl_local_adress);
    detail_s.push("cl_local_adress " + data.cl_local_adress);
    detail_r.push("cl_remote_adress " + data.cl_remote_adress);
    detail_s.push("cl_remote_adress " + data.cl_remote_adress);
    detail_r.push("cl_rx_jitter " + data.cl_rx_jitter);
    detail_s.push("cl_rx_jitter " + data.cl_rx_jitter);
    detail_r.push("cl_tx_jitter " + data.cl_tx_jitter);
    detail_s.push("cl_tx_jitter " + data.cl_tx_jitter);
    detail_r.push("cl_tx_lost " + data.cl_tx_lost);
    detail_s.push("cl_tx_lost " + data.cl_tx_lost);
    detail_r.push("cl_tx_send " + data.cl_tx_send);
    detail_s.push("cl_tx_send " + data.cl_tx_send);

    return {
        tstamp,
        from,
        to,
        groupCount,
        mos,
        id,
        public_id,
        details: [
            {
                from: detail_s,
                to: detail_r,
            },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.tstamp}
                </TableCell>
                <TableCell>{row.from}</TableCell>
                <TableCell>{row.to}</TableCell>
                <TableCell align='center'><b>{row.groupCount}</b></TableCell>
                <TableCell><b>{row.mos}</b></TableCell>
                <TableCell>
                    <Button
                        href={`${process.env.REACT_APP_API_URL}/testcall/report/${row.public_id}`}
                        variant='outlined'>
                        Download
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Von</b></TableCell>
                                        <TableCell><b>Zu</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.details.map((detailsRow) => (
                                        detailsRow.from.map((row, i) => (
                                            <TableRow key={row}>
                                                <TableCell>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            {
                                                                row.split(' ')[0]
                                                                    .replace('codec', 'Codec')
                                                                    .replace('dest_addr_ip', 'IP-Adresse (Quelle)')
                                                                    .replace('dest_addr_port', 'Port (Quelle)')
                                                                    .replace('jitter_max', 'Jitter (max)')
                                                                    .replace('jitter_mean', 'Jitter (mittel)')
                                                                    .replace('jitter_min', 'Jitter (min)')
                                                                    .replace('latency_max', 'Latenz (max)')
                                                                    .replace('latency_mean', 'Latenz (mittel)')
                                                                    .replace('latency_min', 'Latenz (min)')
                                                                    .replace('packets_lost', 'Pakete (verloren) ')
                                                                    .replace('packets_lost_percent', 'Pakete (verloren Prozent)')
                                                                    .replace('packets_send', 'Pakete (gesendet)')
                                                                    .replace('src_addr_ip', 'IP-Adresse (Ziel)')
                                                                    .replace('src_addr_port', 'Port (Ziel)')
                                                                    .replace('time_end', 'Zeit (Ende)')
                                                                    .replace('time_start', 'Zeit (Start)')
                                                                    .replace('cl_local_adress', 'Client Lokale Adresse')
                                                                    .replace('cl_remote_adress', 'Client Lokale Adresse')
                                                                    .replace('cl_rx_jitter', 'Client Jitter')
                                                                    .replace('cl_tx_jitter', 'Client Remote Jitter')
                                                                    .replace('cl_tx_lost', 'Client Paket Lost')
                                                                    .replace('cl_tx_send', 'Client Paket Send')
                                                            }
                                                        </Grid>
                                                        <Grid item xs={6} textAlign={'right'}><b>{row.split(' ')[1]}</b></Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container>
                                                        <Grid item xs={6}><b>{detailsRow.to[i].split(' ')[1]}</b></Grid>
                                                        <Grid item xs={6} textAlign={'right'}>
                                                            {
                                                                detailsRow.to[i]
                                                                    .split(' ')[0]
                                                                    .replace('codec', 'Codec')
                                                                    .replace('dest_addr_ip', 'IP-Adresse (Ziel)')
                                                                    .replace('dest_addr_port', 'Port (Ziel)')
                                                                    .replace('jitter_max', 'Jitter (max)')
                                                                    .replace('jitter_mean', 'Jitter (mittel)')
                                                                    .replace('jitter_min', 'Jitter (min)')
                                                                    .replace('latency_max', 'Latenz (max)')
                                                                    .replace('latency_mean', 'Latenz (mittel)')
                                                                    .replace('latency_min', 'Latenz (min)')
                                                                    .replace('packets_lost', 'Packets (verloren)')
                                                                    .replace('packets_lost_percent', 'Packets (verloren Prozent)')
                                                                    .replace('packets_send', 'Packets (gesendet)')
                                                                    .replace('src_addr_ip', 'IP-Adress (Quelle)')
                                                                    .replace('src_addr_port', 'Port (Quelle)')
                                                                    .replace('time_end', 'Zeit (Ende)')
                                                                    .replace('time_start', 'Zeit (Start)')
                                                                    .replace('cl_local_adress', 'Client Lokale Adresse')
                                                                    .replace('cl_remote_adress', 'Client Lokale Adresse')
                                                                    .replace('cl_rx_jitter', 'Client Jitter')
                                                                    .replace('cl_tx_jitter', 'Client Remote Jitter')
                                                                    .replace('cl_tx_lost', 'Client Paket Lost')
                                                                    .replace('cl_tx_send', 'Client Paket Send')
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ))}
                                </TableBody>
                            </Table><br />

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        from: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        groupCount: PropTypes.number.isRequired,
        mos: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        public_id: PropTypes.string.isRequired,
        details: PropTypes.arrayOf(
            PropTypes.shape({
                from: PropTypes.array.isRequired,
                to: PropTypes.array.isRequired,
            }),
        ).isRequired,
        tstamp: PropTypes.string.isRequired
    }).isRequired,
};


export default function Datatable(props) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    if (props.data === null) {
        return <p>Loading...</p>;
    }

    let rows = [];
    props.data.toReversed().map((entry) => (
        rows.push(createData(entry))
    ))

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div style={{ margin: '10px', paddingTop: '15px' }}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant='h5' textAlign={'center'}><b>Testcalls</b></Typography><br />
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'3%'} />
                                    <TableCell width={'15%'}><b>Datum</b></TableCell>
                                    <TableCell width={'15%'}><b>Von</b></TableCell>
                                    <TableCell><b>Zu</b></TableCell>
                                    <TableCell width={'15%'} align='center'><b>Sprachkanäle</b></TableCell>
                                    <TableCell width={'15%'} ><b>MOS</b></TableCell>
                                    <TableCell width={'5%'} align='center'><b>Report</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : rows
                                ).map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        colSpan={3}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Zeilen pro Seite',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        </>
    );

}